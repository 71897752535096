<template>
    <zw-sidebar @shown="shown" :title="$t('settings.profile.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="4">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required|excluded:Ihre Firma"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.address"
                                        validate="required|excluded:Ihre Adresse"
                                        name="adresse"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.street"
                                        validate="required"
                                        name="street"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.city"
                                        validate="required"
                                        name="city"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.postcode"
                                        validate="required"
                                        name="postcode"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.default"
                                         name="default"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.telefon"
                                        name="telefon"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.fax"
                                        name="fax"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.land"
                                        name="land"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.mobil"
                                        name="mobil"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.amtsgericht"
                                        name="amtsgericht"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.kontoinhaber"
                                        name="kontoinhaber"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.handelsregisterNr"
                                        name="handelsregisterNr"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.bank"
                                        name="bank"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.geschaeftsfuehrer"
                                        name="geschaeftsfuehrer"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.kontonummer"
                                        name="kontonummer"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.steuernummer"
                                        name="steuernummer"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.bankleitzahl"
                                        name="bankleitzahl"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.umsatzsteuerIdentifikationsnummer"
                                        name="umsatzsteuerIdentifikationsnummer"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.iban"
                                        name="iban"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.email"
                                        name="email"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.bic"
                                        name="bic"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.webseite"
                                        name="webseite"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-textarea-group v-model="form.confidencial"
                                           name="confidencial"
                                           :label-prefix="labelPrefix"
                        ></zw-textarea-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.return_email"
                                        name="return_email"
                                        validate="email"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.agb_link"
                                        name="agb_link"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-card
                    :header="$t('settings.documents.group.documents')"
                    header-tag="header"
                >
                    <b-card-text>
                        <b-row v-if="form.id">
                            <b-col cols="6">
                                <zw-media v-model="form.logo"
                                          type="logo"
                                          :cols="6"
                                          accepted-files="image/*"
                                          :parent_id="form.id"
                                          :remove_all="true"
                                          preview
                                ></zw-media>
                            </b-col>
                            <b-col cols="6">
                                <zw-select-group v-model="form.logo_position"
                                                 name="logo_position"
                                                 :label-prefix="labelPrefix"
                                                 :options="{
                                                                'left': $t('common.button.left'),
                                                                'right': $t('common.button.right'),
                                                             }"
                                ></zw-select-group>
                                <zw-input-group v-model="form.logo_width"
                                                name="logo_width"
                                                type="number"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                                <zw-input-group v-model="form.logo_margin_x"
                                                name="logo_margin_x"
                                                type="number"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                                <zw-input-group v-model="form.logo_margin_y"
                                                name="logo_margin_y"
                                                type="number"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="2">
                                <zw-input-group v-model="form.invoice_prefix"
                                                name="invoice_prefix"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.invoice_separator_1"
                                                name="invoice_separator_1"
                                                :maxLength="2"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-row>
                                    <b-col cols="3">
                                        <zw-switch-group v-model="form.use_year"
                                                         name="use_year"
                                                         @input="updateInvoiceNumberPlaceholder"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>
                                    <b-col cols="9">
                                        <zw-input-group :value="form.year"
                                                        v-if="form.use_year == 1"
                                                        name="year"
                                                        :label-prefix="labelPrefix"
                                                        :readonly="true"
                                        ></zw-input-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.invoice_separator_2"
                                                name="invoice_separator_2"
                                                :maxLength="2"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.last_invoice_number"
                                                name="last_invoice_number"
                                                type="number"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <zw-input-group v-model="form.invoice_number_placeholder"
                                                name="invoice_number_placeholder"
                                                :label-prefix="labelPrefix"
                                                :readonly="true"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="2">
                                <zw-input-group v-model="form.gutschrift_prefix"
                                                name="gutschrift_prefix"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.gutschrift_separator_1"
                                                name="gutschrift_separator_1"
                                                :maxLength="2"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-row>
                                    <b-col cols="3">
                                        <zw-switch-group v-model="form.gutschrift_use_year"
                                                         name="use_year"
                                                         @input="updateGutschriftNumberPlaceholder"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>
                                    <b-col cols="9">
                                        <zw-input-group :value="form.gutschrift_year"
                                                        v-if="form.gutschrift_use_year == 1"
                                                        name="year"
                                                        :label-prefix="labelPrefix"
                                                        :readonly="true"
                                        ></zw-input-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.gutschrift_separator_2"
                                                name="gutschrift_separator_2"
                                                :maxLength="2"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="form.last_gutschrift_number"
                                                name="last_gutschrift_number"
                                                type="number"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <zw-input-group v-model="form.gutschrift_number_placeholder"
                                                name="gutschrift_number_placeholder"
                                                :label-prefix="labelPrefix"
                                                :readonly="true"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <zw-file-group v-model="form.background"
                                               name="background"
                                               :label-prefix="labelPrefix"
                                               document-type="background"
                                               accept=".pdf"
                                ></zw-file-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-file-group v-model="form.background_2"
                                               name="background_2"
                                               :label-prefix="labelPrefix"
                                               document-type="background"
                                               accept=".pdf"
                                ></zw-file-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-file-group v-model="form.background_a5"
                                               name="background_a5"
                                               :label-prefix="labelPrefix"
                                               document-type="background"
                                               accept=".pdf"
                                ></zw-file-group>
                            </b-col>
                            <b-col cols="6">
                                <zw-file-group v-model="form.background_a5_2"
                                               name="background_a5_2"
                                               :label-prefix="labelPrefix"
                                               document-type="background"
                                               accept=".pdf"
                                ></zw-file-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Customer-Category-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
            },
            form: {},
            labelPrefix: 'settings.profile.label.',
        }
    },
    methods: {
        ...mapGetters('Profiles', ['getProfile']),
        updateInvoiceNumberPlaceholder() {
            if (this.form.use_year == 1) {
                this.form.invoice_number_placeholder = this.form.invoice_number_placeholder.replace(/\{separator_1\}\{separator_2\}/g, `{separator_1}{year}{separator_2}`);
            } else {
                this.form.invoice_number_placeholder = this.form.invoice_number_placeholder.replace(/\{year\}/g, '');
            }
        },
        updateGutschriftNumberPlaceholder() {
            if (this.form.gutschrift_use_year == 1) {
                this.form.gutschrift_number_placeholder = this.form.gutschrift_number_placeholder.replace(/\{separator_1\}\{separator_2\}/g, `{separator_1}{year}{separator_2}`);
            } else {
                this.form.gutschrift_number_placeholder = this.form.gutschrift_number_placeholder.replace(/\{year\}/g, '');
            }
        },
        shown() {
            const store = this.$store.dispatch('Profiles/fetchProfile', this.payload.id)

            Promise.all([store])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getProfile()))
                    }
                    this.form.year = new Date().getFullYear()
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Profiles/saveProfile', this.form)
                        .then((response) => {
                            localStorage.setItem('firstLogin', 'false')
                            this.commonAfterSave(response)
                        })
                        // .catch(errors => {
                        //     this.$refs['observer'].setErrors(errors)
                        //     this.showValidationError()
                        // })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>